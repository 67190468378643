:root {
    --bs-primary: #da2036;
    --bs-primary-rgb: 218, 32, 54;
}

#nprogress .bar {
    background: #FFFFFF;
    height: 3px;
}

.no-header-added #nprogress .bar {
    background: #da2036;
}

#nprogress .peg {
    box-shadow: none;
}

#nprogress .spinner {
    display: none;
}

.btn {
    border: 0px;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #da2036;
    --bs-btn-border-color: #b71a2c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #b71a2c;
    --bs-btn-hover-border-color: #b71a2c;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #b71a2c;
    --bs-btn-active-border-color: #b71a2c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #da2036;
    --bs-btn-disabled-border-color: #da2036;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
    --bs-navbar-color: rgba(255, 255, 255, 0.80);
    --bs-navbar-hover-color: rgba(255, 255, 255, 0.90);
    --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
}

.feature-icon {
    width: 4rem;
    height: 4rem;
    border-radius: .75rem;
}

.icon-square {
    width: 3rem;
    height: 3rem;
    border-radius: .75rem;
}

.text-shadow-1 {
    text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25);
}

.text-shadow-2 {
    text-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25);
}

.text-shadow-3 {
    text-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25);
}

.card-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.feature-icon-small {
    width: 3rem;
    height: 3rem;
}

.operation-icon {
    fill: #FFF;
}

.operation-tile {
    margin-bottom: 40px;
}

.form-signin form {
    opacity: 0;
    transition: opacity 0.4s;
}

.form-signin form.show {
    opacity: 1;
}

.form-signin {
    width: 400px;
    margin-top: 100px !important;
}

.form-signin .login-logo {
    width: 200px;
    display: block;
    margin: auto;
    margin-bottom: 50px;
}

.form-signin .form-floating {
    margin-bottom: 10px;
}

.form-signin .form-floating:last-child {
    margin-bottom: 0px;
}

.app-main {
    min-height: 100vh;
}

.main-header .navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
}

.header-logo {
    width: 65px;
}

.op-status-line-title {
    font-weight: 600;
    margin-right: 5px;
}

.run-credit-store-button {
    margin-right: 10px;
}

.op-credit-result-title {
    font-weight: 600;
}

.op-credit-result-action {
    margin-right: 10px;
}

.op-credit-result-ouput {
    background-color: lightgray;
    padding: 10px;
    border-radius: 5px;
    max-height: 300px;
    max-width: 500px;
    overflow: auto;
    font-family: monospace;
    font-size: 14px;
}

.alert-object {
    position: fixed;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
}

.alert-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 900px) {
    .form-signin {
        width: 325px;
    }
    
    .form-signin .login-logo {
        width: 150px;
    }
}